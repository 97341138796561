import { createSlice } from '@reduxjs/toolkit'
import { appliedJobs, applyToJob, createJobs, getJobs, jobFilter, readJob, updateJob } from './JobsThunk';
import { IAccountsState, IJobs } from '../../../types/Types';

interface IJobsDetails {
    id: string,
    title: String,
    image: string,
    salary: String,
    location: String,
    type: String,
    currency: string;
    highlights: string;
    skills: object;
    languages: String[],
    job_details: string;
    applications: String;
    applied: boolean;
    count: number;
}
interface IJobsState {
    jobs: IJobs[];
    isLoading: Boolean;
    isValid: Boolean;
    error: string | null;
    jobDetailsValid: boolean,
    jobDetails: IJobsDetails | null;
    success: boolean;
    jobId: string | null;
    applyValid: boolean;
    applying: boolean;
    appliedJobs: IAccountsState[];
    appliedJob: IAccountsState | object;
}

const jobsInitialState: IJobsState = {
    isLoading: false,
    error: null,
    isValid: false,
    success: false,
    jobDetailsValid: false,
    jobDetails: null,
    jobs: [],
    jobId: null,
    applyValid: false,
    applying: false,
    appliedJobs: [],
    appliedJob: {}
}

export const jobSlice = createSlice({
    name: 'jobs',
    initialState: jobsInitialState,
    reducers: {
        setJob: (state, action) => {
            state.jobs = action.payload;
        },
        addJob: (state, action) => {
            state.jobs.push(action.payload);
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        getJobSalary: (state, action) => {
            state.isLoading = true;
            console.log(action)
            state.jobs = state.jobs.filter(job => job.salary <= action.payload.salary);
            state.isLoading = false;
        }
    },
    extraReducers(builder) {

        builder.addCase(appliedJobs.pending, (state: IJobsState, action: {
            payload: any, type: string;
        }) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        });
        builder.addCase(appliedJobs.fulfilled, (state: IJobsState, action: {
            payload: any, type: string;
        }) => {
            state.isLoading = false;
            state.error = null;
            state.success = false;
            state.appliedJobs = action.payload.data;
        });

        builder.addCase(appliedJobs.rejected, (state: IJobsState, action: {
            payload: any, type: string;
        }) => {
            state.isLoading = false;
            state.error = null;
            state.success = false;
            state.appliedJobs = [];
        });
        builder.addCase(getJobs.pending, (state: IJobsState, action: {
            payload: any, type: string;
        }) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        });
        builder.addCase(getJobs.fulfilled, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            state.jobs = action.payload;
            state.isValid = action.payload.is_valid;
            state.success = true;
        });
        builder.addCase(getJobs.rejected, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload?.error;
            state.jobs = [];
            state.success = false;
        });

        builder.addCase(jobFilter.pending, (state: IJobsState, action: {
            payload: any, type: string;
        }) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        });
        builder.addCase(jobFilter.fulfilled, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            state.jobs = action.payload;
            state.isValid = action.payload.is_valid;
            state.success = true;
        });
        builder.addCase(jobFilter.rejected, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload?.error;
            state.jobs = [];
            state.success = false;
        });

        builder.addCase(createJobs.pending, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = true;
        });
        builder.addCase(createJobs.fulfilled, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            state.jobs.push(action.payload.data);
            //@ts-ignore
            state.jobId = action.payload.data.id;
        });
        builder.addCase(createJobs.rejected, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload.error;
        });
        builder.addCase(readJob.pending, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = true;
            state.success = false;
        });
        builder.addCase(readJob.fulfilled, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            state.jobDetails = action.payload.data;
            state.jobDetailsValid = action.payload.is_valid;
            state.success = true;
        });
        builder.addCase(readJob.rejected, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            state.success = true;
            state.jobDetails = null;
            //@ts-ignore
            state.jobDetailsValid = action.payload?.is_valid;
        });
        builder.addCase(updateJob.pending, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = true;
            state.success = false;
        });
        builder.addCase(updateJob.fulfilled, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            state.success = true;
        });
        builder.addCase(updateJob.rejected, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            state.success = true;
        });
        builder.addCase(applyToJob.pending, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            // state.isLoading = true;
            // state.success = false;
            state.applyValid = false;
            state.applying = true;

        });
        builder.addCase(applyToJob.fulfilled, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            // state.isLoading = false;
            // state.success = true;
            state.applyValid = true;
            state.applying = false;
            const jobId = action.payload.data.job_id;
            state.jobs = state.jobs.map((job: any) => {
                if (job.id === jobId) {
                    job.applied = true;
                    job.count = Number(job.count) + 1
                }
                return job;
            });
            if (state.jobDetails !== null) {
                state.jobDetails.count = state.jobDetails.count + 1;
                state.jobDetails.applied = true;
            }
            state.appliedJobs.push(action.payload.data);
        });
        builder.addCase(applyToJob.rejected, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            // state.isLoading = false;
            // state.success = true;
            state.applyValid = false;
            state.applying = false
        });
    }
});

export const { setJob, addJob, setError, setIsLoading, setSuccess, getJobSalary } = jobSlice.actions;

export default jobSlice.reducer;