import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import './SalaryFilter.css';
import { useCallback, useEffect, useRef } from "react";
interface ISalaryFilterProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    salaryRange: number | null;
    setSalaryRange: React.Dispatch<React.SetStateAction<number | null>>;
}
const SalaryFilter = ({ setVisible, visible, salaryRange, setSalaryRange }: ISalaryFilterProps) => {
    const darkMode = useSelector((state: RootState) => state.user?.darkMode);

    const filterRangeRef = useRef(null);

    const handleClick = useCallback((e: any) => {
        // if (selectedItem) { }
        //@ts-ignore
        if (filterRangeRef?.current?.contains(e.target)) {
            return;
        }
        setVisible(false);
    }, [setVisible]);


    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, [handleClick]);


    return <div ref={filterRangeRef} className={`filter-drop-down dropdown p-3 shadow col-2 ${visible ? 'v active' : 'd-none'} ${darkMode ? ' dark-mode' : ''}`} >
        <div className="d-flex justify-content-between">
            <div>Minimum</div>
            <div>${salaryRange !== null && salaryRange * 10}k/year</div>
        </div>
        <input type="range" min="0" max="25" value={salaryRange !== null ? salaryRange : 0} onChange={e => setSalaryRange(Number(e.target.value))}
        />
    </div>
}
export default SalaryFilter;