import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { BASE_URL, USER_API } from "../../constants";


export const registeUser = createAsyncThunk<any, { email: string, password: string, account_type_id: string, name: string }, { state: RootState }>('user/registerUser', async (userData, thunkApi) => {
    try {
        const registerUserRes = await fetch(BASE_URL + USER_API + 'signup', {
            body: JSON.stringify(userData),
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST"
        });
        if (registerUserRes.status === 200) {
            let res = await registerUserRes.json();
            if (res?.message && !res?.link) {
                return thunkApi.rejectWithValue({ msg: res.message })
            }
            // if (res?.message && res?.link) {
            //     const apiverifyUser = await fetch(res?.link, {
            //         headers: {
            //             "Content-Type": "application/json"
            //         },
            //     });
            //     if (apiverifyUser.ok) {

            //     }
            //     return res;
            // }
            return res;
            // return await registerUserRes.json();
        }
        else {
            return new Error(await registerUserRes.json())
        }
    } catch (err) {
        return thunkApi.rejectWithValue(err)
    }
});



export const loginUser = createAsyncThunk<any, { email: string, password: string }, { state: RootState }>('user/loginUser', async (userData, thunkApi) => {
    try {
        const createJob = await fetch(BASE_URL + USER_API + 'login', {
            body: JSON.stringify(userData),
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            method: "POST"
        });
        if (createJob.status === 200) {
            const res = await createJob.json()
            if (res?.msg) {
                return thunkApi.rejectWithValue({ msg: res.msg })
            }
            if (res?.message) {
                return thunkApi.rejectWithValue({ msg: res.message })
            }
            return res?.token;
        }
        else {
            return thunkApi.rejectWithValue(await createJob.json())
        }
    } catch (err) {
        return thunkApi.rejectWithValue(err)
    }
});