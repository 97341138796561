import { BsChat, BsCheck2, BsClock } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RxCalendar } from "react-icons/rx";
import { Col, Row } from "reactstrap";
interface ISideBarContainerProps {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const SideBarContainer = ({ setIsOpen }: ISideBarContainerProps) => {
    return <Row>
        <Col>
            <Row>
                <Col sm={3} className='d-flex'>
                    <img src="Avatar.png" alt="img" className='m-auto' style={{
                        background: "#D9D9D9",
                        height: 50,
                        width: 50,
                        borderRadius: "50%"
                    }} />
                </Col>
                <Row className='col-sm-9'>
                    <Col sm={9} className='p-0'>
                        <div>Maria Morales</div>
                        <span>EMAE Talent Acquisition Partner(SAP)</span>
                    </Col>
                    <Col sm={3} className='p-0'>
                        <FiEdit2 className='actions' />
                        &nbsp;
                        <RiDeleteBin6Line className='actions' />
                    </Col>
                </Row>
            </Row>
            <div className="secondary-details">
                <p>
                    <span>
                        <BsClock />  {new Date().toLocaleDateString('en-us', {
                            month: 'short',
                            day: '2-digit',
                        })}
                    </span>

                </p>
                <p>11 <BsChat /></p>
            </div>
            <Row>
                <Col xs={6}>
                    <RxCalendar fontSize={25} />
                    Interview 08.02  16:00
                </Col>
                <Col xs={6}>
                    <BsCheck2 />
                    Accepted
                </Col>
            </Row>
        </Col>
    </Row>
}
export default SideBarContainer;