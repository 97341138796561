import { Button, Col, Input, Row } from "reactstrap";
import { useEffect } from 'react';
// import ExploreCategories from "../../Components/Common/Explore/ExploreCategories";
import { AppDispatch, RootState } from "../../store";
import { useSelector } from 'react-redux';
import JobCardSkeleton from "../../Components/Common/JobCard/JobCardSkeleton";
import { useState, lazy, Suspense } from 'react';
import ExploreCategoriesSkeleton from "../../Components/Common/Explore/ExploreCategoriesSkeleton";
import { AiFillCloseCircle, AiOutlineSearch } from 'react-icons/ai';
import { useDispatch } from "react-redux";
import './HomePage.css';
import { appliedJobs, getJobs, jobFilter } from "../../store/Slice/Jobs/JobsThunk";
import { BsChevronDown } from "react-icons/bs";
import SearchDropDown from "../../Components/Common/SearchDropDown/SearchDropDown";
import SalaryFilter from "../../Components/Common/SalaryFilter/SalaryFilter";
import LocationFilter from "../../Components/Common/LocatioFilter/LocationFilter";
import BenefitsMenu from "../../Components/Common/BenifitsMenu/BenifitsMenu";
import { getJobSalary } from "../../store/Slice/Jobs/Jobs";
// import SalaryFilter from "../../Components/Common/SalaryFilter/SalaryFilter";
const ExploreCategories = lazy(() => import("../../Components/Common/Explore/ExploreCategories"));
const JobCard = lazy(() => import("../../Components/Common/JobCard/JobCard"));

const CategoriesData = [
    {
        title: "Marketing & Sales",
        src: "/svg/Vector (1).svg",
        subTitle: "237 Jobs Available"
    },
    {
        title: "Software & Development",
        src: "/svg/noun-work-3632619 3.svg",
        subTitle: "237 Jobs Available"
    },
    {
        title: "Human Research & Deve",
        src: "/svg/Group.svg",
        subTitle: "237 Jobs Available"
    },
    {
        title: "Analytics",
        src: "/svg/Vector (1).svg",
        subTitle: "237 Jobs Available"
    },
    {
        title: "Goverment Jobs",
        src: "/svg/Vector (3).svg",
        subTitle: "237 Jobs Available"
    },
    {
        title: "Business & Consulting",
        src: "/svg/Group (1).svg",
        subTitle: "237 Jobs Available"
    },
    {
        title: "Customer Support Care",
        src: "/svg/Vector (4).svg",
        subTitle: "237 Jobs Available"
    },
    {
        title: "Customer Support Care",
        src: "/svg/Group 380.svg",
        subTitle: "237 Jobs Available"
    }
]
interface IFilterCriteria {
    searchValue: string[];
    salaryFilter: number | null;
    locationFilter: string[];
    benifitFilter: string[];
}
const HomePage = () => {
    const { isLoading, jobs, success: isSuccess } = useSelector(
        (state: RootState) => state.job
    );
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const [loadMoreCount, setLoadMoreCount] = useState(3);
    const loadCards = Array(loadMoreCount).fill(1);

    // useEffect(() => {
    //     dispatch(getJobs());
    // }, [dispatch]);

    const [visible, setVisible] = useState(false);
    const [salaryRange, setSalaryRange] = useState<number | null>(null);
    const [showSalaryFilter, setShowSalaryFilter] = useState(false);
    const [showLocationFilter, setShowLocationFilter] = useState(false);
    const [location, setLocation] = useState('');
    const [benefitsMenu, setBenifitsMenu] = useState(false);
    const [benfitSearch, setBenifitSearch] = useState('');
    const [searchValue, setSearchValue] = useState('');

    const [filterCriteria, setFilterCriteria] = useState<IFilterCriteria>({
        searchValue: [],
        salaryFilter: null,
        locationFilter: [],
        benifitFilter: []
    });
    useEffect(() => {
        dispatch(appliedJobs())
    }, [dispatch])
    useEffect(() => {
        setFilterCriteria((state) => {
            const { ...prevState } = state;
            return {
                ...prevState,
                searchValue: searchValue !== '' ? [
                    ...prevState.searchValue,
                    searchValue
                ] : [...prevState.searchValue],
                salaryFilter: salaryRange,
                locationFilter: location !== '' ? [
                    ...prevState.locationFilter,
                    location
                ] : [...prevState.locationFilter],
                benifitFilter: benfitSearch !== '' ? [
                    ...prevState.benifitFilter,
                    benfitSearch
                ] : [...prevState.benifitFilter]
            }
        });
        setSearchValue('');
        setLocation('');
        setBenifitSearch('');
    }, [searchValue, salaryRange, location, benfitSearch])

    useEffect(() => {
        if (filterCriteria.searchValue.length > 0) {
            dispatch(jobFilter({ title: filterCriteria.searchValue[0] }))
        }
        else {
            if (filterCriteria.salaryFilter !== null && filterCriteria.salaryFilter > 0) {
                dispatch(getJobSalary({ salary: Number(filterCriteria.salaryFilter) }))
            }
            else {
                dispatch(getJobs());
            }

        }
    }, [filterCriteria.searchValue, dispatch, filterCriteria.salaryFilter])
    // console.log(searchValue)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        if (!visible) {
            setVisible(true);
        }
    };
    const handleClickScroll = () => {
        const element = document.getElementById('searchSection');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return <>
        <div>
            <div>
                <h1 style={{ textAlign: "center", fontSize: 120, fontWeight: 700 }}>GET A JOB IN
                    TECHNOLOGY</h1>
            </div>
            <div>
                <p style={{ textAlign: "center", fontSize: 25 }}>786 jobs & 35.000 candidates are registered!</p>
            </div>
        </div>
        <Row className="justify-content-center m-4">
            <Col xs={6} className="form-group has-search">
                {!searchValue ? <AiOutlineSearch className="fa fa-search form-control-feedback p-2" /> : null}
                <Input type="text" className="form-control" onClick={() => {
                    handleClickScroll()
                }} value={searchValue} onChange={handleChange} placeholder="your next job?" onFocus={() => setVisible(true)} />
                {/* <SearchDropDown visible={visible} setVisible={setVisible} searchValue={searchValue} setSearchValue={setSearchValue} /> */}
            </Col>
        </Row>
        <Row>
            <h3 style={{ textAlign: "center" }}>Explore By Categories</h3>
            {CategoriesData.map((categories: any, index: number) => {
                return <Col xl={3} sm={6} key={index} className={user?.darkMode ? 'dark-mode' : ''} >
                    <Suspense fallback={<ExploreCategoriesSkeleton />}>
                        <ExploreCategories src={categories.src} subTitle={categories.subTitle} title={categories.title} />
                    </Suspense>
                </Col>
            })}
        </Row >
        <Row className="mt-5" id="searchSection">
            <Row className="mt-4 mb-4">
                <h3 className="mt-1 mb-2" style={{ textAlign: "center" }}>Newest Jobs for you</h3>
                <h5 style={{ textAlign: "center" }}>Get the fastest application so that your name is above other </h5>
            </Row>
            {/* <Row className="d-flex justify-content-evenly home-top-btns">
                <Col><Button>Job Field <img src="./svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                <Col> <Button onClick={() => { }} >Location <img src="./svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                <Col>
                    <Button onClick={() => { }
                    }>Work Load <img src="./svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                <Col><Button>Pay rate <img src="./svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                <Col><Button>Seniority <img src="./svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                <Col><Button>Startup <img src="./svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                <Col><Button>Languages <img src="./svg/noun-arrow-down.svg" alt="1" /></Button></Col>
            </Row> */}
            <div className="d-flex justify-content-between" >
                <div className="d-flex">
                    <div className="m-1">
                        <Button className={"searchButton " + (user.darkMode ? ' dark-mode ' : '')} onClick={() => setVisible(true)}>🔍 Search <BsChevronDown /></Button>
                        <SearchDropDown searchValue={searchValue} setSearchValue={setSearchValue} setVisible={setVisible} visible={visible} />
                    </div>
                    <div className="m-1">
                        <Button className="searchButton" onClick={() => setShowLocationFilter(true)}>🌏 Location <BsChevronDown /></Button>
                        <LocationFilter searchValue={location} setSearchValue={setLocation} setVisible={setShowLocationFilter} visible={showLocationFilter} />

                    </div>
                    <div className="m-1">
                        <Button className="searchButton" onClick={() => setShowSalaryFilter(true)}>💵 Salary <BsChevronDown /></Button>
                        <SalaryFilter visible={showSalaryFilter} setVisible={setShowSalaryFilter} salaryRange={salaryRange} setSalaryRange={setSalaryRange} />
                    </div>
                    <div className="m-1">
                        <Button className="searchButton" onClick={() => setBenifitsMenu(true)}>🎪 Benefits <BsChevronDown /></Button>
                        <BenefitsMenu visible={benefitsMenu} setVisible={setBenifitsMenu} searchValue={benfitSearch} setSearchValue={setBenifitSearch} />
                    </div>
                </div>
                <div >
                    <div className="m-1">
                        <select defaultValue="⚙️ Sort by" className="btn btn-secondary">
                            <option value="⚙️ Sort by">⚙️ Sort by</option>
                            <option value="date">🆕 Latest jobs</option>
                            <option value="salary">💵 Highest paid</option>
                            <option value="views">👀 Most viewed</option>
                            <option value="applied">✅ Most applied</option>
                            <option value="hot">🔥 Hottest</option>
                            <option value="benefits">🎪 Most benefits</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className=" mt-3 filter-option-tags">
                {filterCriteria.searchValue.length > 0 ?
                    filterCriteria.searchValue.map((search: any, index: number) => {
                        return <Button className="w-auto" key={index}>{search} <AiFillCloseCircle
                            onClick={() => {
                                setFilterCriteria(state => {
                                    return {
                                        ...state,
                                        searchValue: filterCriteria.searchValue.filter((val: any, i: number) => i !== index)
                                    }
                                })
                                setSearchValue('')
                            }} /></Button>
                    }) : null}
                {filterCriteria.salaryFilter !== null ? <Button>🤑 &gt;
                    ${salaryRange !== null ? salaryRange * 10 : 0}k/yr <AiFillCloseCircle onClick={() => {
                        setFilterCriteria(state => {
                            return {
                                ...state,
                                salaryFilter: null
                            }
                        })
                        setSalaryRange(null)
                    }} />
                </Button> : null}
                {filterCriteria.locationFilter.length > 0 ?
                    filterCriteria.locationFilter.map((location: any, index: number) => {
                        return <Button className="w-auto" key={index}>{location} <AiFillCloseCircle onClick={() => {
                            setFilterCriteria((state: any) => {
                                return {
                                    ...state,
                                    locationFilter: filterCriteria.locationFilter.filter((val, i) => i !== index)
                                }
                            });
                            setLocation('');
                        }} /></Button>
                    })
                    : null}
                {filterCriteria.benifitFilter.length > 0 ?
                    filterCriteria.benifitFilter.map((benifit: string, index: number) => {
                        return <Button className="w-auto" key={index}>{benifit} <AiFillCloseCircle onClick={() => {
                            setFilterCriteria((state: any) => {
                                return {
                                    ...state,
                                    benifitFilter: filterCriteria.benifitFilter.filter((val, i) => i !== index)
                                }
                            });
                            setBenifitSearch('');
                        }} /></Button>
                    })
                    : null}
            </div>
            <div className="mt-4">
                {isLoading ? loadCards.map((_, index: number) => {
                    return <JobCardSkeleton key={`skeleton-` + index} />
                }) : null}
                {isSuccess ? jobs.map((job: any, index: number) => {
                    return <Suspense key={index} fallback={<JobCardSkeleton />}>
                        <JobCard id={job.id} key={index} applications={job.count} image={job.image}
                            languages={job.skills?.list}
                            location={job.location}
                            currency={job.currency}
                            applied={job?.applied}
                            salary={job?.salary} title={job?.title} type={job.type} createdAt={job.createdAt}
                            details={job?.job_details}
                        />
                    </Suspense>
                }) : null}
                {/* {loadCards.map((_, index: number) => {
                    return <JobCardSkeleton key={`skeleton-` + index} />
                })} */}
            </div>
            {jobs.length === 0 ? <>
                <div className="text-center">
                    <h1 style={{ fontSize: 100 }}>😔</h1>
                    <div>
                        <h4 style={{ fontSize: 50 }}>Sorry!! We can't found any records !!</h4>
                    </div>
                </div>
            </> : null}
            {jobs.length > 0 ?
                <Row className="justify-content-center mt-3">
                    <Col xs={3} className="text-center">
                        <Button onClick={() => setLoadMoreCount(3)}>Load more</Button>
                    </Col>
                </Row> : null}
        </Row >
    </>
}
export default HomePage;