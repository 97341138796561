import { useCallback, useEffect, useRef, useState } from "react";
import './BenifitsMenu.css';
import { searchFilter } from "../../../utils/helper/searchFilter";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
const list = [
    { id: 1, name: '🎅 We hire old(and young) ', type: '🎅 We hire old(and young) ' },
    { id: 2, name: '🚫 No politics at work ', type: '🚫 No politics at work ' },
    { id: 3, name: '💰 401(k) ', type: '💰 401(k) ' },
    { id: 4, name: '🌎 Distributed team', type: '🌎 Distributed team' },
    { id: 5, name: '⏰ Async', type: '⏰ Async' },
    { id: 6, name: '🤓 Vision insurance', type: '🤓 Vision insurance' },
    { id: 7, name: '🦷 Dental insurance', type: '🦷 Dental insurance' },
    { id: 8, name: '🚑 Medical insurance', type: '🚑 Medical insurance' },
    { id: 9, name: '🏖 Unlimited vacation', type: '🏖 Unlimited vacation' },
    { id: 10, name: '🏖 Paid time off', type: '🏖 Paid time off' },
    { id: 11, name: '📆 4 day workweek', type: '📆 4 day workweek' },
    { id: 12, name: '💰 401k matching', type: '💰 401k matching' },
    { id: 13, name: '🏔 Company retreats', type: '🏔 Company retreats' },
    { id: 14, name: '🏬 Coworking budget', type: '🏬 Coworking budget' },
    { id: 15, name: '📚 Learning budget', type: '📚 Learning budget' },
    { id: 16, name: '💪 Free gym membership', type: '💪 Free gym membership' },
    { id: 18, name: '🧘 Mental wellness budget', type: '🧘 Mental wellness budget' },
    { id: 19, name: '🖥 Home office budget', type: '🖥 Home office budget' },
    { id: 20, name: '🥧 Pay in crypto', type: '🥧 Pay in crypto' },
    { id: 21, name: '🥸 Pseudonymous', type: '🥸 Pseudonymous' },
    { id: 22, name: '💰 Profit sharing', type: '💰 Profit sharing' },
    { id: 23, name: '💰 Equity compensation', type: '💰 Equity compensation' },
    { id: 24, name: '⬜️ No whiteboard interview', type: '⬜️ No whiteboard interview' },
    { id: 25, name: '👀 No monitoring system', type: '👀 No monitoring system' },
];
interface IBenifitsMenuProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    searchValue: string;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>
}
const BenefitsMenu = ({ visible, setVisible, searchValue, setSearchValue }: IBenifitsMenuProps) => {
    const darkMode = useSelector((state: RootState) => state.user?.darkMode);

    const [, setSelectedItem] = useState(null);
    const dropdownRef = useRef(null);

    const handleClick = useCallback((e: any) => {
        // if (selectedItem) { }
        //@ts-ignore
        if (dropdownRef?.current?.contains(e.target)) {
            return;
        }
        setVisible(false);
    }, [setVisible]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, [handleClick]);


    const selectItem = (item: any) => {
        setSearchValue(item.name);
        setSelectedItem(item.id);
        setVisible(false);
    };
    return <div ref={dropdownRef} className={`benifits-drop-down col-5 dropdown ${visible ? 'v active' : ''} ${darkMode ? ' dark-mode' : ''}`}>
        {visible && (
            <ul>
                {!list && (
                    <li key="zxc" className="dropdown_item p-3">
                        no result
                    </li>
                )}
                {list &&
                    searchFilter(searchValue, list).map((x: any) => (
                        <li
                            key={x.id}
                            onClick={() => selectItem(x)}
                            className="dropdown_item p-3"
                        >
                            <div className="item_text1">{x.name}</div>
                        </li>
                    ))}
            </ul>
        )}
    </div>
}

export default BenefitsMenu;