import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { RootState } from "../../../store";
import { Link } from "react-router-dom";
interface IUserProps {
    avatarUrl: string;
    userName: string;
    email: string;
    contactNumber: string;
}
const User = ({ avatarUrl, userName, email, contactNumber }: IUserProps) => {
    const user = useSelector((state: RootState) => state.user);

    return <Card className={"border-0 " + (user?.darkMode ? 'dark-mode ' : ' ')} >
        <CardBody>
            <Row>
                {user?.userToken ? <>
                    <Col xs={3} className="d-flex align-items-center">
                        <img src={avatarUrl} alt="profile" />
                    </Col>
                    <Col xs={9}>
                        <h4>{userName}</h4>
                        <p className="m-0">{email}</p>
                        <p className="m-0">{contactNumber}</p>
                    </Col> </> :
                    <>
                        <Col xs={3} className="d-flex align-items-center">
                            <img src={avatarUrl} alt="profile" />
                        </Col>
                        <Col xs={9} className="text-center">
                            <p>Please login </p>
                            <Link to={"/login"} ><Button color="primary">Login</Button></Link>
                        </Col> </>
                }
            </Row>
        </CardBody>
    </Card>
}
export default User;