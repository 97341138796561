import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { BASE_URL, JOB_API } from "../../constants";
import { setLoginRequired } from "../User/User";


export const getJobs = createAsyncThunk<any, undefined, { state: RootState }>('job/getJobs', async (_, thunkApi) => {
    try {
        const getJob = await fetch(BASE_URL + JOB_API + '?limit=5&offset=0&fields=id,status&sort=id');
        if (getJob.status === 200) {
            return await getJob.json();
        }
    } catch (err) {
        return thunkApi.rejectWithValue({
            error: {
                err
            }
        })
    }
});

export const jobFilter = createAsyncThunk<any, { title: string }, { state: RootState }>('job/filterJob', async ({ title }, thunkApi) => {
    try {
        const getJob = await fetch(BASE_URL + JOB_API + '?limit=5&offset=0&fields=id,status&sort=id&title=' + title);
        if (getJob.status === 200) {
            return await getJob.json();
        }
    } catch (err) {
        return thunkApi.rejectWithValue({
            error: {
                err
            }
        })
    }
})

export const createJobs = createAsyncThunk<any, any, { state: RootState }>('job/createJob', async (jobData, thunkApi) => {
    try {
        const user = thunkApi.getState().user;
        jobData.account_id = 1;
        jobData.org_id = 1;
        jobData.skills = {
            list: jobData.skills.map((skill: any) => {
                return skill?.value
            })
        }
        const job = {
            data: [jobData]
        }
        const createJob = await fetch(BASE_URL + JOB_API, {
            body: JSON.stringify(job),
            headers: {
                "Authorization": "Bearer " + user?.userToken,
                "Content-Type": "application/json",
            },
            method: "POST"
        });
        if (createJob.status === 200) {
            return await createJob.json();
        }
        else {
            return new Error(await createJob.json())
        }
    } catch (err) {
        return thunkApi.rejectWithValue(err)
    }
});

export const readJob = createAsyncThunk<any, { jobId: string | undefined }, { state: RootState }>('job/readJob', async ({ jobId }, thunkApi) => {
    try {
        const readJobRes = await fetch(BASE_URL + JOB_API + jobId);
        if (readJobRes.status === 200) {
            const res = await readJobRes.json();
            if (!res.is_valid) {
                return thunkApi.rejectWithValue({ is_valid: res.is_valid })
            }
            return res;
        }
        else {
            return new Error(await readJobRes.json())
        }
    } catch (err) {
        return thunkApi.rejectWithValue(err);
    }
})

export const updateJob = createAsyncThunk<any, any, { state: RootState }>('job/updateJob', async (jobData, thunkApi) => {
    try {
        const updateJob = await fetch(BASE_URL + JOB_API, {
            body: JSON.stringify(jobData),
            method: "PUT"
        });
        if (updateJob.status === 200) {
            return await updateJob.json();
        }
        else {
            return new Error(await updateJob.json());
        }
    } catch (err) {
        return thunkApi.rejectWithValue(err);
    }
})


export const applyToJob = createAsyncThunk<any, any, { state: RootState }>('job/applyJob', async (jobData, thunkApi) => {
    try {
        const user = thunkApi.getState().user;
        if (!user.userToken) {
            thunkApi.dispatch(setLoginRequired({}));
            return thunkApi.rejectWithValue({ loginRequired: true })
        }
        const apply = {
            data: [{
                ...jobData,
                account_id: user?.userData?.id
            }]
        }
        const createJob = await fetch(BASE_URL + '/application/', {
            body: JSON.stringify(apply),
            headers: {
                "Authorization": "Bearer " + user?.userToken,
                "Content-Type": "application/json",
            },
            method: "POST"
        });
        if (createJob.status === 200) {
            return await createJob.json();
        }
        else {
            return new Error(await createJob.json())
        }
    } catch (err) {
        return thunkApi.rejectWithValue(err)
    }
});


export const appliedJobs = createAsyncThunk<any, undefined, { state: RootState }>('job/appliedJobs', async (_, thunkApi) => {
    try {
        const user = thunkApi.getState().user;
        let account = user.userToken ? '&account_id=' + user.userData?.id : '';
        const createJob = await fetch(BASE_URL + '/application/?limit=5&offset=0&fields=id,job_id,account_id,resume,status&sort=id' + account, {
            // body: JSON.stringify(apply),
            headers: {
                "Authorization": "Bearer " + user?.userToken,
                "Content-Type": "application/json",
            },
            // method: "POST"
        });
        if (createJob.status === 200) {
            return await createJob.json();
        }
        else {
            return new Error(await createJob.json())
        }
    } catch (err) {
        return thunkApi.rejectWithValue(err)
    }
});